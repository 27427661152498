.posterPreviewCard {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: var(--size-spacing-02);
  box-shadow: 0 var(--size-spacing-01) var(--size-spacing-02) rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--color-neutral-300);

  @media only screen and (max-width: 470px) {
    scale: 0.35;
    transform: translateY(-1070px) translateX(-705px);
  }

  @media screen and (max-width: 600px) and (min-width: 471px) {
    scale: 0.55;
    transform: translateY(-480px) translateX(-350px);
  }

  @media screen and (max-width: 900px) and (min-width: 601px) {
    scale: 0.65;
    transform: translateY(-320px) translateX(-170px);
  }

  @media screen and (min-width: 901px) {
    scale: 0.65;
    transform: translateY(-310px);
  }

  @media print {
    max-width: 790px;
    width: 740px;
    margin: 0;
    height: 980px;
    overflow: hidden;
  }

  @media print and (max-width: 600px) {
    width: 720px;
  }
}

.header {
  position: absolute;
  top: -1px;
  width: inherit;
  height: 80px;
  display: flex;
  justify-content: center;
  background: white;
  opacity: 0.9;
  z-index: 2;

  @media print {
    width: 100%;
  }
}

.imageContainer {
  width: 100%;
  overflow: hidden;
  height: 600px;

  @media print {
    height: 490px;
    width: 100%;
  }

}

.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: ellipse(100% 85% at 35% 15%);
}

.avatarImage {
  position: absolute;
  transform: translateY(-160%);
  right: var(--size-spacing-04);
  z-index: 2;
  height: auto;

  @media print {
    width: 9rem;
    transform: translateY(-120%);
    right: var(--size-spacing-05);
  }

}

.content {
  padding: var(--size-spacing-06);
  width: 100%;
  text-align: left;

  @media print {
    padding: var(--size-spacing-01) var(--size-spacing-06);
  }
}

.titleText {
  font-size: var(--font-size-09);
  line-height: var(--font-size-09);
}

.subTitleText {
  color: var(--color-text-lighter);
  font-weight: var(--font-weight-light);
}

.quote {
  font-style: italic;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-spacing-03) var(--size-spacing-04);
  background-image: var(--color-purple-300);
  border-bottom-left-radius: var(--size-spacing-02);
  border-bottom-right-radius: var(--size-spacing-02);
  width: 100%;
  align-items: flex-start;
  background-color: var(--color-brand-lighter);
  height: 230px;
  overflow: hidden;

  @media print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: var(--color-brand-lighter);
    height: 210px;
  }
}

.qrcodeContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.qrcode {
  width: 190px;
  height: 190px;

  @media print {
    width: 150px;
    height: 150px;
  }
}

.helpText {
  font-size: var(--font-size-07);

  @media print {
    font-size: var(--font-size-05);
    line-height: var(--font-size-05);
    margin-bottom: var(--size-spacing-03);
    color: #000000;
  }
}

.donationInfo {
  flex: 2;
  text-align: left;
}

.justgivingIcon {
  transform: scale(1.3);
}

.iconContainer {
  padding-left: var(--size-spacing-04);
  margin-left: var(--size-spacing-03);
}

.donationText {
  font-size: var(--font-size-06);
}

.printButton {
  position: sticky;
  width: fit-content;
}

.posterPreview {
  margin: 0 auto;
  padding: var(--size-spacing-04) 0;
}

.bottomButtonContainer {
  @media only screen and (max-width: 470px) {
    transform: translateY(-750px);
    padding-bottom: var(--size-spacing-07);
  }

  @media screen and (max-width: 600px) and (min-width: 471px) {
    transform: translateY(-550px);
    padding-bottom: var(--size-spacing-07);
  }

  @media screen and (max-width: 900px) and (min-width: 601px) {
    transform: translateY(-400px)
  }

  @media screen and (min-width: 901px) {
    transform: translateY(-380px);
  }
}

.printNavBar {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;

  @media print {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: var(--size-spacing-04);
  }
}

.textHalf {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    width: 85%;
  }

}

.textBox {
  width: 90%;
  text-align: left;

  @media only screen and (max-width: 600px) {
    text-align: justify;
    font-size: var(--font-size-01);
    line-height: var(--font-size-01);
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--size-spacing-05);
  width: 50%;
  justify-content: space-evenly;

  @media only screen and (max-width: 600px) {
    padding: var(--size-spacing-03);
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

}

.ctaButton {
  width: 35%;
  min-width: var(--size-spacing-12);

  @media only screen and (max-width: 600px) {
    width: 180%;
  }

  @media only screen and (max-width: 900px) {
    margin-top: var(--size-spacing-03);
  }
}

.hiddenElement {
  position: absolute;
  top: 290px;
  left: 0;
  width: 900px;
  height: 1270px;
  background: var(--color-neutral-lighter);

  @media only screen and (min-width: 470px) {
    display: none;
  }
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
}

.qrloading {
  position: relative;
  top: var(--size-spacing-07);
  margin: auto;
}
