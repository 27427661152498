.card {
  box-shadow: 0px 4px 12px 0px #00000033;
}

.jgIconContainer {
  display: flex;
  padding: var(--size-spacing-04);
  justify-content: center;
}

.image {
  width: 100%;
  object-fit: cover;
}

.title {
  margin-bottom: var(--size-spacing-03);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.organiserInfo {
  display: flex;
  flex-direction: column;
}

.noWrap {
  text-wrap: nowrap;
}
