.copyText {
  border: 1px solid var(--color-neutral-dark);
  border-radius: 4px;
  white-space: pre-line;
  background-color: var(--color-neutral-lighter);
  height: auto;
  width: auto;
  padding: var(--size-spacing-04);
  display: flex;
  flex-direction: column;
  word-wrap: break-word;

  &.isCopied {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
}

.text {
  font-style: italic;
  color: var(--color-text-light);
}

.copyButton {
  border: none;
  background: none;
  align-self: center;
  padding: 0;
}

.successContainer {
  background-color: var(--color-success-lighter);
  padding: var(--size-spacing-04);
  border: 1px solid var(--color-success-base);
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  gap: var(--size-spacing-02);

  svg {
    fill: var(--color-success-base);
  }
}
