.progressBar {
  --border-radius: 5px;

  height: 5px;
  background-color: var(--color-brand-lighter);
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.progress {
  background-color: var(--color-brand-base);
  position: absolute;
  inset: 0;
  border-radius: var(--border-radius);
}
