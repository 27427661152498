.copyUrl {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: var(--size-spacing-04);
  cursor: pointer;
  background-color: var(--color-neutral-lighter);
}

.url {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-text-lighter);
  flex: 1;
  text-align: left;
}

.linkIcon {
  width: 20px;
  height: 20px;
  fill: var(--color-neutral-dark);
}
