.buttonGroup {
  display: flex;
  width: 100%;
}

.item {
  flex: 1;
  border: 1px solid var(--color-neutral-base);
  background-color: #ffffff;
  color: var(--color-interaction-base);
  border-radius: 4px;
  display: flex;

  &.selectedItem {
    background-color: #d9e9ff;
    border-color: var(--color-interaction-base);
    z-index: 1;
  }

  &:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:last-of-type):not(.selectedItem) {
    border-right-color: inherit;
  }

  &:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
}

.button {
  background-color: transparent;
  color: inherit;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border: none;
}
