@import '../../styles/breakpoints';

.sharePage {
  display: flex;
  flex-direction: column;
  padding: var(--size-spacing-05);

  @include device-small {
    padding: var(--size-spacing-07);
  }
}

.copyUrl {
  max-width: 530px;
  align-self: center;
}

.textLighter {
  color: var(--color-text-lighter);

  li {
    list-style: none;
  }
}

.alignCenter {
  text-align: center;
}

.socialCopyButton {
  padding: initial;
}

.badge {
  margin-right: var(--size-spacing-06);
}

.iconList {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, var(--size-spacing-09));
  grid-gap: var(--size-spacing-06);
  justify-content: center;
}

.printModal {
  height: 900px;
}