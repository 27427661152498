.heading {
  text-align: center;
}

.recommendationContainer {
  padding: var(--size-spacing-04);
  background-color: var(--color-neutral-light);
  border-radius: 4px;
}

.recommendationHeading {
  text-align: center;
}

.pageCardWrapper {
  padding: var(--size-spacing-05) var(--size-spacing-04);
  background-color: var(--color-brand-lighter);
  border-radius: 8px;
}

.storyPageCard {
  // Box-shadow does not look ok in the screenshot
  box-shadow: none;
}

.instagram {
  background-color: #ff0069;
  border: none;
  color: var(--color-neutral-lighter);
  margin-bottom: var(--size-spacing-05);

  &:hover {
    background-color: #cc0054;
  }

  &:active {
    background-color: #99003f;
  }
}

.snapchat {
  background-color: #000000;
  border: none;
  color: var(--color-neutral-lighter);
  margin-bottom: var(--size-spacing-05);

  &:hover {
    background-color: #333333;
  }

  &:active {
    background-color: #666666;
  }
}

.tiktok {
  background-color: #000000;
  border: none;
  color: var(--color-neutral-lighter);
  margin-bottom: var(--size-spacing-05);

  &:hover {
    background-color: #333333;
  }

  &:active {
    background-color: #666666;
  }
}

.facebook {
  background-color: #3B5998;
  border: none;
  color: var(--color-neutral-lighter);
  margin-bottom: var(--size-spacing-05);
}

.image {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid var(--color-grey-300);
}